.sidebar {
  flex: 1;
  border-right: 0.5px solid rgb(230, 227, 227);
  min-height: 100vh;
  background-color: #222F3F;
  // width: 270px !important;
  // width: 200px;
  min-width: 230px;
  max-width: 230px;

  .top {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;

    .logo {
      font-size: 20px;
      font-weight: bold;
      color: #6439ff;
    }
  }

  hr {
    height: 0;
    border: 0.5px solid rgb(230, 227, 227);
  }

  .center {
    padding-left: 10px;

    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      .title {
        font-size: 11px;
        font-weight: bold;
        color: #d1d5db;
        margin-top: 15px;
        margin-bottom: 5px;
      }

      li {
        display: flex;
        align-items: center;
        padding: 5px;
        cursor: pointer;

        .icon {
          font-size: 18px;
          color: #9ca3af;
        } 

        span{
            font-size: 14px;
            font-weight: 600;
            color: #9ca3af;
            margin-left: 10px;
            
        }
        &:hover .icon{
          color: white;
        }
        &:hover span{
          color: white;
        }
      }
    

    }
  }

  .bottom{
      display: flex;
      align-items: center;
      margin: 10px;

      .colorOption{
          width: 20px;
          height: 20px;
          border-radius: 5px;
          border: 1px solid #7451f8;
          cursor: pointer;
          margin: 5px;

          &:nth-child(1){
              background-color: whitesmoke;
          }
          &:nth-child(2){
              background-color: #333;
          }
          &:nth-child(3){
              background-color: darkblue;
          }
      }
  }

  .toDark{
    background-color: #222F3F;
  }
  .bottomSet{
    background-color: #222C30;
  }
}
